html {
    background: url(/images/texture/textile.png) transparent;
    background-color: #dcd4cc;
    overflow-y: scroll;
}

body {
    font-size: 24px;
    font-family: "Ubuntu", Sans-Serif;
}

body > main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon { -webkit-touch-callout: none; user-select: none; }
.icon.button { cursor: pointer; border-radius: 0.2em; }
.icon.button > * { pointer-events: none; }

svg.icon.facebook { background: #3b5999; }
svg.icon.facebook:active { background: #728FC8; }
svg.icon.googleplus { background: #dd4b39; }
svg.icon.googleplus:active { background: #EC9990; }

body > header {
    height: 5em;
    font-size: 1.2em;
    text-align: center;
    display: flex; align-items: center; justify-content: space-around; align-self: stretch;
}
body > header > h1 {
    flex: 1 0 auto;
    font-size: 2em;
}
body > header > h1 a {
    color: #000; text-decoration: none; position: relative; z-index: 1;
}
body > header > h1 a:before {
    display: block;
    content: "_";
    position: absolute;
    z-index: -1;
    background-color: #fff;
    color: rgba(255,255,255,0);
    top: 0; bottom: 0;
    left: 0; right: 0;
    padding: 0em;
    margin: 0em;
    white-space: nowrap;
    box-shadow: 0 1px RGBA(0,0,0,0.5);
    border-radius: 0.15em;
    pointer-events: none;
    transform: scaleX(0);
    transform-origin: 0 50%;
    visibility: hidden;
    transition: transform 0.2s ease 0.2s, padding 0.2s, margin 0.2s, visibility 0s ease 0.4s;
}
body > header > h1 a:hover:before {
    padding: 0.3em;
    margin: -0.3em;
    transform: scaleX(1);
    visibility: visible;
    transition: transform 0.2s, padding 0.2s ease 0.2s, margin 0.2s ease 0.2s, visibility 0s;
}


body > header > .pronunciation {
    flex: 1 0 auto;
    opacity: 0.4;
}

.gameDescription {
    overflow: hidden;
    max-width: 38em;
    border-radius: 0.2em;
    margin: 0 0 1em;
}
.gameDescription > header { background: url(/images/texture/leather.png); background-color: #263238; color: #fff; text-shadow: 0 1px RGBA(0,0,0,0.5); padding: 0.6em; z-index: 1; }
.gameDescription > header > .dice { display: inline-block; min-width: 1em; background: #fff; border-radius: 0.15em; text-align: center; margin: 0 0.5em; padding: 0 0.25em; box-shadow: 0 1px RGBA(0,0,0,0.5); font-family: ""Ubuntu" Mono", Monospace; color: #263238; }
.gameDescription > header > .name { position: relative; cursor: pointer; }
.gameDescription > header > .name:before {
    display: block;
    content: attr(data-name) " (" attr(data-player) ")";
    position: absolute;
    background-color: #fff;
    color: rgba(255,255,255,0);
    text-shadow: 0 1px RGBA(0,0,0,0);
    top: 0;
    left: 0;
    padding: 0em;
    margin: 0em;
    white-space: nowrap;
    box-shadow: 0 1px RGBA(0,0,0,0.5);
    border-radius: 0.15em;
    pointer-events: none;
    transform: scaleX(0);
    transform-origin: 0 50%;
    visibility: hidden;
    transition: transform 0.2s ease 0.2s, color 0.2s, text-shadow 0.2s, padding 0.2s, margin 0.2s, visibility 0s ease 0.4s;
}
.gameDescription > header > .name:hover:before {
    padding: 0.3em;
    margin: -0.3em;
    text-shadow: 0 1px RGBA(0,0,0,0.5);
    transform: scaleX(1);
    visibility: visible;
    transition: transform 0.2s, color 0.2s ease 0.2s, text-shadow 0.2s ease 0.2s, padding 0.2s ease 0.2s, margin 0.2s ease 0.2s;
    color: #263238;
}
.gameDescription > .body > .story { background: url(/images/texture/leather.png) #fffcf9; font-family: "Georgia", Cursive, Serif; padding: 0.3em 1em; line-height: 1.8em; }
.gameDescription *::selection { color: #fff; background-color: #263238; }
.gameDescription > .body > .attributes { background: url(/images/texture/leather.png); background-color: #263238; font-family: ""Ubuntu" Mono", Monospace; color: #fff; display: table; width: 100%; padding: .3em; table-layout: fixed; min-height: 0.05em; }
.gameDescription > .body > .attributes ul { margin: 0; padding: 0.5em; display: table-cell; list-style: none; border-right: 1px solid #fffcf9; }
.gameDescription > .body > .attributes ul:last-of-type { border-right: none; }
.gameDescription > .body > .attributes ul li { text-shadow: 0 1px RGBA(0,0,0,0.5); }
.gameDescription > .body > .attributes ul .header { background-color: #fff; border-radius: 0.15em; text-align: center; margin-bottom: 0.5em; color: #263238; }

.loginbuttons {
    display: flex;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
}
.loginbuttons h2 { order: 2; padding: 0 2em; }
.loginbuttons .button { flex: 0 1 7rem; height: 7rem; }
.loginbuttons .facebook { order: 1; }
.loginbuttons .googleplus { order: 3; }

.articleList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.articleList > h2 {
    flex: 0 1 100%;
    font-size: 1.5em;
}
.articleList > article {
    display: inline-block;
    flex: 1 1 33.3333333333333333333333333%;
}

.campaignList { max-width: 57em; }
.campaignList > h2 { display: none; }
.campaignList > article {
    flex: 1 1 calc(33.3333333333333333333333333% - 0.6em);
    border-radius: 0.2em;
    margin: 0.3em;
    min-width: 15em;
    background: url(/images/texture/leather.png) #fffcf9;

    display: flex;
    flex-direction: column;
}

.campaignList > article > header {
    flex: 0 1 auto;
    color: #fff;
    background: url(/images/texture/leather.png);
    background-color: #263238;
    text-shadow: 0 1px RGBA(0,0,0,0.5);
    padding: 0.6em;
    border-radius: 0.2em 0.2em 0 0;
}
.campaignList > article > p {
    flex: 1 0 auto;
    font-family: "Georgia", Cursive, Serif;
    padding: 0.3em 1em;
    line-height: 1.8em;
}
.campaignList > article > a {
    flex: 0 0 auto;
    text-align: center;
    text-decoration: none;
    padding: 0.3em;
    background: url(/images/texture/leather.png);
    background-color: #263238;
    color: #fff;
    position: relative;
    z-index: 1;
    border-radius: 0 0 0.2em 0.2em;
    transition: color 0.2s, text-shadow 0.2s;
}
.campaignList > article > a:before {
    display: block;
    content: "_";
    position: absolute;
    z-index: -1;
    background-color: #fff;
    color: rgba(255,255,255,0); text-shadow: none;
    top: 0; bottom: 0;
    left: 0; right: 0;
    padding: 0em;
    margin: 0em;
    white-space: nowrap;
    box-shadow: 0 1px RGBA(0,0,0,0.5);
    border-radius: 0.15em;
    pointer-events: none;
    transform: scaleX(0);
    transform-origin: 0 50%;
    visibility: hidden;
    transition: transform 0.2s ease 0.2s, padding 0.2s, margin 0.2s, visibility 0s ease 0.4s;
}
.campaignList > article > a:hover:before {
    padding: 0.3em;
    margin: -0.3em;
    transform: scaleX(1);
    visibility: visible;
    transition: transform 0.2s, padding 0.2s ease 0.2s, margin 0.2s ease 0.2s, visibility 0s;
}
.campaignList > article > a:hover {
    transition: color 0.4s 0.2s, text-shadow 0.4s 0.2s;
    color: #263238;
    text-shadow: 0 1px RGBA(0,0,0,0.5);
}

@media (max-width: 42em) {
    body {
        font-size: 0.8em;
    }
}
